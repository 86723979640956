import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import queryString from "query-string";

import { isBrowser } from "@util/helper";
import { defaultCart, useStore } from "@state/store";
import { Loading } from "@global";
import { createDocument } from "@util/firebase";
import { navigate } from "gatsby";
import { pages } from "@util/constants";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  transform: scale(2);
  font-size: 0.5em;
  p {
    margin-top: 5px;
  }
`;

export const handleCheckoutComplete = async (
  setCompletePostPaymentFunc: Dispatch<SetStateAction<boolean>>,
) => {
  setCompletePostPaymentFunc(false);
  const { cart, user } = useStore.getState();
  if (!cart || !user || !user?.id) {
    setCompletePostPaymentFunc(false);
    return;
  }

  await createDocument(
    "orders",
    {
      ...cart,
      uid: user.id,
      createdAt: new Date().toISOString(),
      status: "complete",
      agentId: user.agentId ?? "",
    },
    cart.uuid,
  );

  //Update Vaultre users
  const taskData = { cart, user };
  await fetch(`/.netlify/functions/orderVault`, {
    method: "POST",
    body: JSON.stringify(taskData),
  });

  await useStore.setState({
    cart: defaultCart(),
    checkoutInitiated: false,
    cartOpen: false,
  });
  setCompletePostPaymentFunc(true);
  navigate(pages.orders);
};

const getParam = (key: string) => {
  if (!isBrowser()) return "";

  const parsedSearch = queryString.parse(window.location.search);

  return parsedSearch[key];
};

const PaymentSuccessful = () => {
  const [paymentVerified, setPaymentVerified] = useState<boolean | null>(null);
  const [completePostPaymentFunc, setCompletePostPaymentFunc] = useState(false);
  const { cart } = useStore();

  useEffect(() => {
    if (!cart.uuid) return;
    const merchantSession = cart.uuid;
    const msParam = getParam("ms");

    setPaymentVerified(merchantSession === msParam);
  }, []);

  useEffect(() => {
    if (paymentVerified === false) {
      navigate("/");
    }
    if (paymentVerified) {
      handleCheckoutComplete(setCompletePostPaymentFunc);
    }
  }, [paymentVerified]);

  return (
    <Wrapper>
      <LoadingWrapper>
        <Loading color="main" baseColor="lightBlue" width="" />
        {paymentVerified && completePostPaymentFunc ? (
          <p>Payment finalisation completed. You will be redirected to you account page.</p>
        ) : (
          <p>Please wait while we finalise payment completion. Please do not refresh the page.</p>
        )}
      </LoadingWrapper>
    </Wrapper>
  );
};

export default PaymentSuccessful;
